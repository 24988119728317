<template>
  <Loader
    :value="promises.fetchOne"
    theme="default"
    class="purchase-create view"
    :message="$t('loading', { resource: this.$tc('residential').toLowerCase() }) + '...'"
  >
    <div class="container">
      <div class="purchase-create__metadata">
        <ResidentialCard :value="$path('reference', item)" />
        <SellerCard :value="$path('reference.project.roles.salesman.0', item)" />
      </div>
      <StepperComponent v-if="item.id" :value="steps" ref="stepper" state-handler="none">
        <template #breadcrumb="{ item, index }">
          <button type="button" disabled class="elder-stepper__breadcrumb-button">
            <span class="elder-stepper__breadcrumb-icon">
              {{ index + 1 }}
            </span>
            <span>{{ item.label }}</span>
          </button>
        </template>
      </StepperComponent>
    </div>
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import { StepperComponent } from 'vue-elder-stepper'
import Steps from './Steps'
import ResidentialCard from '@/components/Residential/Card'
import SellerCard from '@/components/Residential/Seller'
import { loadLanguageAsync } from '@/i18n'

export default {
  props: {
    id: String,
    accessToken: String,
  },
  watch: {
    id: {
      handler: 'fetch',
      immediate: true,
    },
  },
  computed: {
    ...mapState('Contract', ['item', 'promises']),
    steps() {
      let props = {
        value: this.item,
      }

      return Steps().map(raw => {
        const { icon, ...s } = raw
        s.props = {
          id: raw.id,
          ...(s.props || {}),
          ...props,
          icon,
        }
        s.listeners = {
          ...(s.listeners || {}),
        }
        return s
      })
    },
  },
  methods: {
    ...mapActions('Contract', {
      fetch(dispatch) {
        const notFound = () => this.$router.replace({ name: 'notfound' })

        return dispatch('fetch', [{ id: this.id, accessToken: this.accessToken }, { query: 'Purchase' }])
          .then(() => {
            if (!this.item.id) return notFound()
            loadLanguageAsync(this.$path('item.reference.project.metadata.language', this))

            if (['signed', 'accepted', 'declined'].includes(this.item.status)) this.$refs.stepper.next()
          })
          .catch(notFound)
      },
    }),
  },
  metaInfo() {
    return {
      title: this.item.id
        ? `${this.$t('purchaseContract')} | ${this.item.reference.name} - ${this.item.reference.project.name}`
        : this.$t('loading', { resource: this.$t('purchaseContract').toLowerCase() }) + '...',
    }
  },
  components: {
    StepperComponent,
    ResidentialCard,
    SellerCard,
  },
}
</script>

<style lang="scss">
.purchase-create {
  // background-color: var(--light-grey);
  min-height: 100vh;

  .container {
    max-width: 950px;
  }

  .elder-stepper__breadcrumb-button:disabled {
    cursor: not-allowed;
  }

  .elder-stepper__breadcrumbs {
    margin: 1.5rem 0;

    .elder-stepper__breadcrumb {
      padding: 0.5rem 0;
    }
  }

  &__metadata {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    margin: 2rem auto 0;
  }
}
</style>
