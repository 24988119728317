<template>
  <Card v-if="value" class="residential-card" theme="border" :thumbnail="$path('media.cover.0.url', value)">
    <div class="residential-card__name">{{ $path('project.name', value) }} - {{ value.name }}</div>
    <div v-if="value.sale" class="residential-card__price">
      <span>{{ $path('sale.value', value) | Currency }}</span>
      <span v-if="$path('sale.fixed', value)" class="residential-card__price-fixed"
        >({{ $t('residentialSaleFixed') }})</span
      >
    </div>
    <slot />
  </Card>
</template>

<script>
import { Currency } from '@/filters'
import { mapState } from 'vuex'

import Card from '@/components/Card'

export default {
  props: {
    value: Object,
  },
  filters: {
    Currency,
  },
  components: {
    Card,
  },
}
</script>

<style lang="scss">
.residential-card {
  &__name {
    font-weight: bold;
  }

  &__price {
    &-fixed {
      margin-left: 0.3em;
      opacity: 0.6;
    }
  }
}
</style>
