<template>
  <Card v-if="value" class="seller-card" :theme="theme">
    <template #header>
      <div class="seller-card__thumbnail" :style="{ backgroundImage: `url(${value.avatar})` }"></div>
    </template>

    <div class="seller-card__info">
      <div class="seller-card__name">{{ value.name }}</div>
      <div v-if="value.title" class="seller-card__title">{{ value.title }}</div>
    </div>
    <div class="seller-card__contact">
      <a :href="`mailto:${value.email}`">{{ value.email }}</a>
      <a v-if="value.phone" :href="`tel:${value.phone}`">{{ value.phone }}</a>
    </div>
    <slot></slot>
  </Card>
</template>

<script>
import Card from '@/components/Card'

export default {
  props: {
    value: Object,
    theme: {
      type: String,
      default: 'border',
    },
  },
  components: {
    Card,
  },
}
</script>

<style lang="scss">
.seller-card {
  .card__header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: none;
  }

  &__name {
    font-weight: bold;
  }

  &__title {
    opacity: 0.7;
  }

  &__contact {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  a {
    color: var(--primary);
    text-decoration: none;
  }

  .elder-button {
    font-size: 0.8em;
  }

  &__thumbnail {
    $size: 150px;

    width: $size;
    height: $size;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    flex-shrink: 0;
    background-size: cover;
    background-position: center;
    background-color: css-alpha('border-color', 0.4);
    border-radius: 50%;
    color: css-darken('border-color', 20%);
  }
}
</style>
